<template>
  <div style="height:432px;">
    <h3>学校统计表格</h3>
    <el-table
    size="mini"
      :data="dataList"
      border
      stripe
      show-summary
      sum-text="总人数"
    >
      <el-table-column prop="schoolName" label="学校名称" />
      <el-table-column prop="payStudentCount" label="已支付学生数量" />
      <el-table-column label="操作" fixed="right" align="center">
        <template v-slot="scoped">
          <el-button
            type="success"
            size="small"
            @click="
              schoolDateStudentHandler(
                scoped.row.schoolId,
                scoped.row.schoolName
              )
            "
          >
            统计学生
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <school-student
      :schoolStudentData="schoolStudentData"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import { listSchoolCountVo } from '@/api/homeChart'
import schoolStudent from '../dialog/schoolStudent.vue'

export default {
  components: { schoolStudent },
  props: {
    queryInfo: {
      startTime: String,
      endTime: String
    }
  },
  mounted () {
    this.listSchoolCountVo()
  },
  watch: {
    'queryInfo.startTime' () {
      this.listSchoolCountVo()
    },
    'queryInfo.endTime' () {
      this.listSchoolCountVo()
    }
  },
  data () {
    return {
      schoolStudentData: {
        isOpen: false,
        schoolId: '',
        schoolName: ''
      },
      dataList: []
    }
  },
  methods: {
    schoolDateStudentHandler (id, schoolName) {
      this.schoolStudentData.isOpen = true
      this.schoolStudentData.schoolId = id
      this.schoolStudentData.schoolName = schoolName
    },
    listSchoolCountVo () {
      listSchoolCountVo(this.queryInfo).then(res => {
        this.dataList = res.data
      })
    },
    closeDialog (data) {
      this.schoolStudentData.isOpen = data.isOpen
      if (data.type === 'success') {
        // 保存成功关闭弹窗后刷新
        this.renderTable()
      }
    }
  }
}
</script>

<template>
  <div>
    <div id="schoolChart" style="width: 100%;height:450px;"></div>
  </div>
</template>

<script>
import { listSchoolCountVo } from '@/api/homeChart'
import * as echarts from 'echarts'

export default {
  props: {
    queryInfo: {
      startTime: String,
      endTime: String
    }
  },
  mounted () {
    this.listSchoolCountVo()
  },
  data () {
    return {
      dataList: [],
      schoolNameList: [],
      payStudentCountList: []
    }
  },
  watch: {
    'queryInfo.startTime' () {
      this.listSchoolCountVo()
    },
    'queryInfo.endTime' () {
      this.listSchoolCountVo()
    }
  },
  methods: {
    listSchoolCountVo () {
      listSchoolCountVo(this.queryInfo).then(res => {
        const data = res.data
        this.schoolNameList = []
        this.payStudentCountList = []
        data.forEach(item => {
          this.schoolNameList.push(item.schoolName)
          this.payStudentCountList.push(item.payStudentCount)
        })
        var myChart = echarts.init(document.getElementById('schoolChart'))
        myChart.setOption({
          title: {
            text: '学校统计图表'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {},
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: this.schoolNameList.reverse()
          },
          series: [
            {
              name: '已支付学生数量',
              type: 'bar',
              data: this.payStudentCountList.reverse(),
              label: {
                show: true,
                position: 'right'
              }
            }
          ]
        })
      })
    }
  }
}
</script>

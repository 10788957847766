<template>
  <div class="page-content">
    <div class="footer">
      <h1>销量排行榜</h1>
      <el-table  :data="dataList" size="mini" border stripe :height="467">
        <el-table-column label="排行" width="50" type="index" align="center">
        </el-table-column>
        <el-table-column prop="promoterName" label="推广员姓名" />
        <el-table-column prop="payStudentCount" label="已支付学生数量" />
        <el-table-column label="操作" fixed="right" align="center">
          <template v-slot="scoped">
            <el-button
              type="success"
              size="mini"
              @click="
                dateStudentHandler(
                  scoped.row.promoterId,
                  scoped.row.promoterName
                )
              "
            >
              统计学生
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-body">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="data.currentPage"
          :page-sizes="[1, 5, 10, 20]"
          :page-size="data.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        />
      </div>
    </div>
    <sales-student
      :salesStudentData="salesStudentData"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import { pagePromoter } from '@/api/homeChart'
import salesStudent from '../dialog/salesStudent.vue'

export default {
  components: {
    salesStudent
  },
  props: {
    queryInfo: {
      startTime: String,
      endTime: String
    }
  },
  data () {
    return {
      dataList: [],
      data: {
        startTime: '',
        endTime: '',
        keyword: '',
        pageSize: 10,
        currentPage: 1
      },
      total: 0,
      salesStudentData: {
        promoterName: '',
        isOpen: false,
        id: ''
      }
    }
  },
  watch: {
    'queryInfo.startTime' () {
      this.pagePromoterCountVo()
    },
    'queryInfo.endTime' () {
      this.pagePromoterCountVo()
    }
  },
  mounted () {
    this.pagePromoterCountVo()
  },
  methods: {
    pagePromoterCountVo () {
      this.data.startTime = this.queryInfo.startTime
      this.data.endTime = this.queryInfo.endTime

      pagePromoter(this.data).then(res => {
        this.dataList = res.data.list
        this.total = res.data.total
      })
    },

    dateStudentHandler (id, promoterName) {
      this.salesStudentData.promoterName = promoterName
      this.salesStudentData.isOpen = true
      this.salesStudentData.id = id
    },

    /* 分页选择 */
    handleSizeChange (size) {
      this.data.pageSize = size
      this.pagePromoterCountVo(this.queryInfo)
    },
    handleCurrentChange (size) {
      this.data.currentPage = size
      this.pagePromoterCountVo(this.queryInfo)
    },

    /* 关闭弹窗 */
    closeDialog (data) {
      this.salesStudentData.isOpen = data.isOpen
      if (data.type === 'success') {
        // 保存成功关闭弹窗后刷新
        this.renderTable()
      }
    }
  }
}
</script>

<style scoped></style>
